import { fetchGet } from "../..";
import { Channel } from "../../../components/Screens/Watch/Live/LiveStreamSection";
import { APIResponse } from "../../../types/responses/APIResponse";
import { MemberPlans } from "../../../types/memberPlans";
import { ScheduleSlotItem } from "../../../types/tvSchedule";
import { selectOptimalCodec } from "../../../components/VideoPlayer/selectOptimalCodec";
import { Player } from "../../../types/replay";
import {
  PerformAPIResponse,
  StreamDataResponse,
  StreamUrlParams,
} from "../../../types/stream";
import { cleanSlug } from "../../../utils/cleanSlug";
import { getDeviceId } from "../../../utils/getDeviceId";
import { Platform } from "react-native";
import { useToast } from "react-native-toast-notifications";
import { APPLE_PAY_AVAILABLE } from "../../../utils/flags";

const outletAuthKey = "18arele3k5ry51xfcefkic6f5o"; // Nurun (website and mobile apps) - We'll implement other platforms later

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
} as const;

export const getUpNext = async (
  skipFirstProgram = true
): Promise<ScheduleSlotItem[]> => {
  const { schedule_slots } = await fetchGet("videos/streams");
  return schedule_slots
    .map((slot, index) => {
      if (skipFirstProgram && index === 0) return;
      const { datetime, episode, id } = slot;
      return {
        title: episode.programme.title,
        description: episode.epg_synopsis,
        startAt: datetime.start,
        id,
      } as ScheduleSlotItem;
    })
    .filter(Boolean);
};

export const getLiveChannelsInfo = async (): Promise<Channel[]> => {
  const videos = await fetchGet("videos/streams");
  const { schedule_slots, streams: allStreams } = videos;

  const channels: Channel[] = [];

  const activeStreams = allStreams?.filter(
    (s) => s.active.state === "on_until"
  );

  activeStreams?.forEach((stream, index) => {
    const { meeting } = stream;
    // RUK 24/7 event
    if (index == 0) {
      channels.push({
        name: "Racing TV",
        title: "Main live channel",
        id: undefined,
        sourceEventType: undefined,
        programmeTitle: schedule_slots[0].episode.programme.title,
        programmeDescription: schedule_slots[0].episode.epg_synopsis,
        backgroundImage: allStreams[0].placeholder_image_url,
        startTime: new Date(
          schedule_slots[0].datetime.start
        ).toLocaleTimeString("en-GB", timeOptions),
        endTime: new Date(schedule_slots[0].datetime.end).toLocaleTimeString(
          "en-GB",
          timeOptions
        ),
      });

      // other active streams
    } else {
      channels.push({
        name: `Racing TV extra ${index}`,
        title: meeting?.track?.name
          ? `${meeting?.track?.name} live`
          : stream.title,
        id: stream.id,
        sourceEventType: stream.source_event_type,
        slug: meeting?.track?.slug,
        programmeTitle: meeting?.track?.name
          ? `${meeting?.track?.name}`
          : stream.title,
        programmeDescription: null, // No description for extra streams - https://www.pivotaltracker.com/story/show/185774281
        backgroundImage: stream.placeholder_image_url,
      });
    }
  });

  return channels;
};

export const checkFreeVideoPeriod = async (): Promise<APIResponse<Player>> => {
  return await fetchGet(`member/watch/streams/channel`);
};

const getStreamUrl = async (
  StreamUrlParams: StreamUrlParams
): Promise<string> => {
  const { baseUrl, outletAuthKey, streamUuid, format, queryParameters, token } =
    StreamUrlParams;
  const url = `${baseUrl}/${outletAuthKey}/${streamUuid}?_fmt=${format}&_fld=${queryParameters}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Origin: "https://www.racingtv.com",
        Referer: "https://www.racingtv.com/",
      },
    });

    if (!response.ok) {
      console.error(
        "Client/Watch/Live::getStreamUrl - Error fetching stream url",
        response.statusText
      );
      return "";
    }

    const data: PerformAPIResponse = await response.json();
    const playerAlias = "iPhonesec";
    const streamLauncher = data.launchInfo.streamLauncher.find(
      (launcher) => launcher.playerAlias === playerAlias
    );

    if (!streamLauncher) {
      console.error(
        "Client/Watch/Live::getStreamUrl - Error fetching stream url",
        "No streamLauncher found"
      );
      return "";
    }

    return streamLauncher.launcherURL;
  } catch (error) {
    console.error(
      "Client/Watch/Live::getStreamUrl - Error fetching stream url",
      error
    );
    return "";
  }
};

const responseWithDeviceId = async (url) => {
  const deviceId = await getDeviceId();

  return fetchGet(
    url,
    {},
    false,
    APPLE_PAY_AVAILABLE
      ? {
          "DEVICE-IDENTIFIER": deviceId,
        }
      : {}
  );
};

const requestWithoutDeviceId = async (url: string) => {
  try {
    return await fetchGet(url);
  } catch (error) {
    return null;
  }
};

const fetchSource = async (
  url: string,
  checkingFreeVidPeriod: boolean = false
): Promise<string> => {
  try {
    const responseWithoutDeviceId = await requestWithoutDeviceId(url);

    // we do this because API will fail if we use device id and there's not a
    // apple pay purchase associated with it. so we only want to fallback to that.
    const stream: StreamDataResponse = responseWithoutDeviceId?.stream
      ? responseWithoutDeviceId.stream
      : (await responseWithDeviceId(url)).stream;

    if (checkingFreeVidPeriod) {
      const freeVidPeriod = response?.stream?.free_period_end;
      if (freeVidPeriod) {
        return freeVidPeriod;
      } else {
        return undefined;
      }
    }

    const {
      data: {
        base_url,
        asset_id,
        params: { _fmt, _fld },
        token,
      },
    } = stream;

    // Now we have data we can build Stats Perform URL and fetch it to get the stream URL
    const streamURL = await getStreamUrl({
      baseUrl: base_url,
      outletAuthKey,
      streamUuid: asset_id,
      format: _fmt,
      queryParameters: _fld,
      token: token,
    } as StreamUrlParams);

    return streamURL;
  } catch (error) {
    console.error(
      "Client/Watch/Live::fetchSource - Error fetching source",
      error
    );
    return "";
  }
};

export const getLiveChannelsSource = async (
  channel?: Channel,
  checkFreeVidPeriod?: boolean
): Promise<string> => {
  // ALTERNATE LIVE STREAM CHANNEL
  if (channel?.sourceEventType === "alternate") {
    return await fetchSource(
      `member/watch/streams/alternates/stream-data/${channel.id}`
    );
  }
  // MAIN LIVE STREAM CHANNEL
  if (!channel?.slug) {
    return await fetchSource(
      "member/watch/streams/channel/stream-data",
      checkFreeVidPeriod
    );
  }
  // EXTRA LIVE STREAM CHANNELS
  return await fetchSource(
    `member/watch/streams/tracks/stream-data/${cleanSlug(channel.slug)}`
  );
};

export const getMemberPlans = async (): Promise<APIResponse<MemberPlans>> => {
  return await fetchGet(`member/plans`);
};
