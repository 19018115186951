import React from "react";
import { View, Platform } from "react-native";
import TableRow from "./TableRow";
import { useResponsive } from "../../hooks/useResponsive";
import RScrollView from "../RScrollView/";
import variables from "../../styles/variables";
import RText from "../RText";
import Icon from "../Icon";

type Props = {
  data?: any; // todo make required - but for now lets us keep the data in this file
  tableStyleDescriptor?: any;
  tableOnPressDescriptor?: any;
  showAllRows?: boolean;
  hideArrows?: boolean;
};

const params = {
  startTime: "2022-11-25",
  track: "test-track",
  slug: "test-slug",
  name: "NewBurry",
};

const jockeyParams = {
  name: "russel-kenmore",
};

const testTableData = {
  header: {
    date: { value: "DATE" },
    course: { value: "COURSE" },
    dist: { value: "DIST." },
    going: { value: "GOING" },
    cl: { value: "CL." },
    type: { value: "TYPE" },
    pos: { value: "POS." },
    beaten: { value: "BEATEN" },
    weight: { value: "WEIGHT" },
    jockey: { value: "JOCKEY" },
    sp: { value: "SP" },
    or: { value: "OR" },
    rtg: { value: "RTG" },
    replay: { value: "REPLAY" },
  },
  body: [
    {
      date: { value: "24Aug17" },
      course: { value: "Yor" },
      dist: { value: "13.0" },
      going: { value: "GS" },
      cl: { value: "1" },
      type: { value: "2yoGp1" },
      pos: { value: "12/15" },
      beaten: { value: "12.25" },
      weight: { value: "10-12" },
      jockey: { value: "Russell Kennemore" },
      sp: { value: "100/30" },
      or: { value: "119" },
      rtg: { value: "121" },
      replay: { value: "REPLAY" },
    },
    {
      date: { value: "24Aug17" },
      course: { value: "Yor" },
      dist: { value: "13.0" },
      going: { value: "GS" },
      cl: { value: "1" },
      type: { value: "2yoGp1" },
      pos: { value: "12/15" },
      beaten: { value: "12.25" },
      weight: { value: "10-12" },
      jockey: { value: "Newburry" },
      sp: { value: "100/30" },
      or: { value: "119" },
      rtg: { value: "121" },
      replay: {
        value: (
          <Icon
            name="playCircled"
            size="medium"
            fill={"palette.rtv.secondary"}
          />
        ),
      },
    },
  ],
};

const testTableStyleDescriptor = {
  table: {
    style: { width: "100%", height: "100%" },
    all: {
      style: {
        paddingHorizontal: 4,
        paddingVertical: 10,
        minWidth: 80,
      },
    },
    fields: {
      jockey: { style: { minWidth: 140 } },
      sp: { style: { alignItems: "flex-end" } },
      or: { style: { alignItems: "flex-end" } },
      rtg: { style: { alignItems: "flex-end" } },
      replay: { style: { alignItems: "flex-end" } },
    },
  },
  header: {
    all: {
      style: {},
      font: { weight: "semiBold", size: "sm", color: "grey" },
    },
    fields: {},
  },
  body: {
    all: {
      style: {
        rowColor: "lightgrey",
        alternateRowColor: "white",
      },
      font: { weight: "regular", size: "sm" },
    },
    fields: {
      date: {
        font: { weight: "semiBold" },
      },
      course: {
        font: { color: "secondary", weight: "semiBold" },
      },
      dist: {
        font: { weight: "semiBold" },
      },
      going: {
        font: { weight: "semiBold" },
      },
      jockey: {
        font: { color: "secondary", weight: "semiBold" },
      },
      rtg: {
        font: { weight: "semiBold" },
      },
    },
  },
};

const testTableOnPressDescriptor = {
  date: {
    onPress: () => console.log("date pressed!"),
  },
  course: {
    link: { route: "Jockey", queryParams: "0011" },
  },
  jockey: {
    link: { route: "Jockey", queryParams: jockeyParams },
  },
};

const Table = ({
  data = testTableData,
  tableStyleDescriptor = testTableStyleDescriptor,
  tableOnPressDescriptor = testTableOnPressDescriptor,
  showAllRows = true,
  hideArrows = false,
}: Props) => {
  const scrollRef = React.useRef(null);
  const [tableWidth, setTableWidth] = React.useState();

  const TableHeader = ({ headerData, headerStyle }) => {
    return (
      <View style={headerStyle.all.style}>
        <TableRow
          data={headerData}
          columnStyle={
            Platform.OS === "web"
              ? tableStyleDescriptor.table.fields?.web ||
                tableStyleDescriptor.table.fields
              : tableStyleDescriptor.table.fields?.mobile ||
                tableStyleDescriptor.table.fields
          }
          tableCellGlobalStyle={[
            tableStyleDescriptor.table.all.style,
            headerStyle.all.style,
          ]}
          tableCellCustomStyle={headerStyle.fields}
          globalFontStyle={headerStyle.all.font}
        />
      </View>
    );
  };

  const RenderTableRow = ({ index, rowData, bodyStyle }) => {
    return (
      <TableRow
        data={rowData}
        columnStyle={
          Platform.OS === "web"
            ? tableStyleDescriptor.table.fields?.web ||
              tableStyleDescriptor.table.fields
            : tableStyleDescriptor.table.fields?.mobile ||
              tableStyleDescriptor.table.fields
        }
        backgroundColour={
          index % 2 === 0
            ? bodyStyle.all.style.rowColor
            : bodyStyle.all.style.alternateRowColor
        }
        tableCellGlobalStyle={[
          tableStyleDescriptor.table.all.style,
          bodyStyle.all.style,
        ]}
        tableCellCustomStyle={bodyStyle.fields}
        navigationIndex={index}
        tableOnPressDescriptor={tableOnPressDescriptor}
      />
    );
  };

  const TableBody = ({ bodyData, bodyStyle, tableOnPressDescriptor }) => {
    return (
      <View style={bodyStyle.all.style}>
        {bodyData?.map((bodyRowObj, index) => {
          return (
            <View key={`tableBody${index}`}>
              {showAllRows ? (
                <>
                  {bodyRowObj.injectedData ? (
                    <View
                      style={{
                        backgroundColor: variables.colors.palette.rtv.primary,
                        padding: variables.spacing.xsmall,
                        alignItems: "center",
                      }}
                    >
                      {bodyRowObj.injectedData.value.map((text, i) => (
                        <RText key={i} weight="semiBold" color="white">
                          {text}
                        </RText>
                      ))}
                    </View>
                  ) : null}
                  <RenderTableRow
                    index={index}
                    rowData={bodyRowObj}
                    bodyStyle={bodyStyle}
                  />
                </>
              ) : (
                index < 3 && (
                  <RenderTableRow
                    index={index}
                    rowData={bodyRowObj}
                    bodyStyle={bodyStyle}
                  />
                )
              )}
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <View style={tableStyleDescriptor.table.style}>
      <RScrollView
        scrollRef={scrollRef}
        nativeID="table"
        horizontal
        childrenWidth={tableWidth}
        elementDistance={300}
        style={{ flex: 1, width: "100%" }}
        contentContainerStyle={{ minWidth: "100%", flexDirection: "column" }}
        showsHorizontalScrollIndicator={false}
        hideArrows={hideArrows}
      >
        <View onLayout={(e) => setTableWidth(e.nativeEvent.layout.width)}>
          <TableHeader
            headerData={data.header}
            headerStyle={tableStyleDescriptor.header}
          />
          <TableBody
            bodyData={data.body}
            bodyStyle={tableStyleDescriptor.body}
            tableOnPressDescriptor={tableOnPressDescriptor}
          />
        </View>
      </RScrollView>
    </View>
  );
};

export default Table;
