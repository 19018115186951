import React, { useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
} from "react-native";
import Icon from "../components/Icon";
import variables from "../styles/variables";

type ScrollDirection = { type: "left" | "right" };

type ScrollControlProps = {
  scrollRef: React.RefObject<ScrollView> | React.RefObject<FlatList>;
  elementDistance: number;
  childrenWidth?: number;
  parentWidth?: number;
};

const sidePadding = variables.spacing.xxsmall;
export const useScrollControl = ({
  scrollRef,
  elementDistance,
  childrenWidth,
  parentWidth,
}: ScrollControlProps) => {
  const [position, setPosition] = useState(0);
  const [scroll, setScroll] = useState<number>(0);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);

  useEffect(() => {
    if (parentWidth && childrenWidth) {
      handleLayout(parentWidth, childrenWidth);
    }
  }, [childrenWidth, parentWidth]);

  useEffect(() => {
    if (scrollRef?.current) {
      // Check if the ref is for a ScrollView
      if ("scrollTo" in scrollRef.current) {
        scrollRef?.current?.scrollTo?.({ x: scroll, animated: true });
      }
      // Check if the ref is for a FlatList
      else if ("scrollToOffset" in scrollRef.current) {
        scrollRef?.current?.scrollToOffset?.({
          offset: scroll,
          animated: true,
        });
      }
    }
  }, [scroll]);

  const handleHideArrow = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    setHideLeftArrow(contentOffset.x - sidePadding < 0);
    setHideRightArrow(
      layoutMeasurement.width + contentOffset.x >=
        contentSize.width - sidePadding
    );
  };

  const handleLayout = (parentWidth: number, childrenWidth) => {
    setHideRightArrow(parentWidth >= childrenWidth);
  };

  // prettier-ignore
  const ScrollIndicator = React.useMemo(() => ({ type }: ScrollDirection) => {

    const hoverRef = React.useRef();

    const handleMouseEnter = () => {
      if (hoverRef.current && Platform.OS === "web") {
        hoverRef.current.children[0].style.width = 50;
        hoverRef.current.style.opacity = 0.5;
      }
    };

    const handleMouseLeave = () => {
      if (hoverRef.current && Platform.OS === "web") {
        hoverRef.current.children[0].style.width = 20;
        hoverRef.current.style.opacity = 0.2;
      }
    };

    const isLeft = type === "left";

    if ((isLeft && hideLeftArrow) || (!isLeft && hideRightArrow))
      return null;

    const style = isLeft ? styles.leftArrow : styles.rightArrow;

    return (
      <Pressable
        ref={hoverRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={[
          styles.background,
          style,
        ]}
        onPress={() => {
          setScroll(
            isLeft ? position - elementDistance : position + elementDistance
          );
        }}
      >
        <Icon
          name="chevronLeft"
          size={"small"}
          fill="palette.rtv.white"
          rotate={isLeft ? 0 : 180}
        />
      </Pressable>
    );
  }, [hideLeftArrow, hideRightArrow, position, elementDistance]);

  return {
    position,
    handleHideArrow,
    hideLeftArrow,
    hideRightArrow,
    setPosition,
    ScrollIndicator,
    handleLayout,
  };
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: variables.colors.palette.rtv.black,
    opacity: 0.2,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: "100%",
  },
  leftArrow: { left: 0, zIndex: 1 },
  rightArrow: { right: 0, zIndex: 1 },
});
