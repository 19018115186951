import React from "react";
import {
  View,
  StyleSheet,
  Platform,
  ViewStyle,
  TouchableOpacity,
} from "react-native";
import variables from "../../../../styles/variables";
import { useResponsive } from "../../../../hooks/useResponsive";
import RText from "../../../RText";
import Icon from "../../../Icon";
import ChevronIcon from "../../../ChevronIcon/ChevronIcon";
import usePressableProps from "../../../../hooks/usePressableProps";
import { Article } from "../../../../types/news";
import { cleanSlug } from "../../../../utils/cleanSlug";
import ShowMore from "../../../SectionHeader/ShowMore";

const { colors, spacing } = variables;

type HeaderProps = {
  heading: string;
  type: "tipster" | "tipster-expert" | "info" | "race";
  style?: ViewStyle;
  rightComponent?: {
    text?: string;
    chevron?: boolean;
  };
  subHeading?: string;
  rtvTrack?: boolean;
  screen?: string;
  params?: Object;
  addBackground?: boolean;
  addStyles?: boolean;
  article?: Article;
};

const TipsterHeader = ({
  heading,
  subHeading,
  rightComponent,
  type,
  style = {},
  rtvTrack,
  screen = "IndividualTipster",
  params,
  addBackground = true,
  addStyles = true,
  article = undefined,
}: HeaderProps) => {
  const { isLargeAndUp } = useResponsive();

  const pressableProps =
    screen &&
    params &&
    usePressableProps({
      screen: screen,
      params: params,
    });

  if (!heading) return;

  return (
    <TouchableOpacity
      style={[
        styles.container,
        style,
        addBackground && styles.containerBackground,
        addStyles && styles.containerPadding,
      ]}
      {...pressableProps}
    >
      <View
        style={[
          styles.leftContainer,
          {
            paddingVertical: isLargeAndUp ? spacing.xsmall : spacing.xxsmall,
          },
        ]}
      >
        <Icon
          name={type === "tipster-expert" ? "eye" : "hat"}
          size="small"
          style={
            addStyles ? styles.tipsterIcon : { marginRight: spacing.xxsmall }
          }
        />
        <View style={type == "race" && styles.race}>
          <View style={styles.heading}>
            <RText
              color="primary"
              family="secondary"
              weight="bold"
              uppercase
              size="sm"
            >
              {heading}
            </RText>
            {rtvTrack && (
              <Icon name="rLogo" size="small" style={styles.rIcon} />
            )}
          </View>
          {!!subHeading && (
            <RText family="secondary" size="sm" style={styles.subHeading}>
              {subHeading}
            </RText>
          )}
        </View>
      </View>
      {article ? (
        <ShowMore
          navigateTo="NewsPost"
          queryParams={{
            slug: cleanSlug(article.slug),
          }}
          text="View Article"
        />
      ) : null}
      {/* bio one liner for bigger screens */}
      {rightComponent && !article ? (
        <View style={styles.rightContainer}>
          {rightComponent.text && (
            <RText style={styles.rightText}>{rightComponent.text}</RText>
          )}
          {rightComponent.chevron && (
            <View style={styles.chevron}>
              <ChevronIcon type="right" />
            </View>
          )}
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: Platform.OS == "web" ? "pointer" : "default",
    width: "100%",
  },
  containerPadding: {
    paddingHorizontal: spacing.xxsmall,
  },
  containerBackground: {
    marginTop: 8,
    backgroundColor: colors.palette.neutral.x100,
  },
  leftContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  tipsterIcon: {
    marginHorizontal: spacing.xxsmall,
  },
  race: {
    marginHorizontal: spacing.xxsmall,
  },
  heading: {
    flexDirection: "row",
  },
  rIcon: {
    marginLeft: 6,
    paddingTop: 1,
  },
  subHeading: {
    marginTop: spacing.xxsmall,
    maxWidth: 850,
  },
  expertEyeIcon: {
    marginLeft: spacing.xxsmall,
  },
  rightContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  rightText: {
    marginHorizontal: spacing.xsmall,
    height: 24,
  },
  chevron: {
    paddingBottom: 1,
  },
});

export default TipsterHeader;
